@import-normalize;

body {
  margin: 0;
  padding: 0;
  font-family: var(--font-family);
  overflow: hidden;
}

#root {
  min-height: 100vh;
  height: 100%;
}

iframe {
  border-width: 0;
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  display: block;
}
